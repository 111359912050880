import { combineReducers } from 'redux';

import { systemReducer } from './system';
import { contactReducer } from './contact';

const rootReducer = combineReducers({
    system: systemReducer,
    contact: contactReducer,
});

export default rootReducer;
