import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import Home from 'scenes/Home';
import { ThemeProvider } from 'styled-components';

import './App.css';

import theme from 'theme';

import store from './store';

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Router>
                    <Switch>
                        {/* Fallbackc */}
                        <Route path="/" component={Home} />
                    </Switch>
                </Router>
            </ThemeProvider>
        </Provider>
    );
};

export default App;
