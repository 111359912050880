import { SystemState, SystemActionTypes, LOGIN_SUCCESS } from './types';
import { RootState } from '../../store';
import { createSelector } from 'reselect';

const initialState: SystemState = {
    loggedIn: false,
    userName: '',
    firstName: '',
    lastName: '',
};

export function systemReducer(
    state = initialState,
    action: SystemActionTypes
): SystemState {
    switch (action.type) {
        case LOGIN_SUCCESS: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
}

export const selectLoggedIn = (state: RootState) => state.system.loggedIn;
export const selectUserName = (state: RootState) => state.system.userName;

export const selectLoggedInId = createSelector(selectLoggedIn, loggedIn => {
    if (loggedIn) {
        return Math.ceil(Math.random() * 100);
    }
    return false;
});
