import { createStore, applyMiddleware, Action } from 'redux';
import rootReducer from './reducers';
import thunk, { ThunkAction } from 'redux-thunk';

const loggerMiddleware = (store: any) => (next: any) => (action: any) => {
    // console.log("STATE BEFORE", store.getState());
    /* Activate for Action dispatching debugging
    if (
        action &&
        ['[object Function]', '[object AsyncFunction]'].includes(
            {}.toString.call(action)
        )
    ) {
        console.info('THUNK DISPATCHED', action);
    } else {
        console.log('ACTION DISPATCHED', action);
    }
    */
    next(action);
    // console.log("STATE AFTER", store.getState());
};

export default createStore(
    rootReducer,
    applyMiddleware(loggerMiddleware, thunk)
);
export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    null,
    Action<string>
>;
