import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';

import arrow from './arrow-right.svg';

function Why({ className }: { className?: string }) {
    const [active, setActive] = useState(false);
    const ref = useRef(null as any);

    const toggle = useCallback(() => {
        setActive(!active);
    }, [active]);

    return (
        <div className={className}>
            <div
                className={`${className}__why${active ? ' active' : ''}`}
                onClick={toggle}
                ref={ref}
            >
                <img src={arrow} alt="" />
                WHY
            </div>
            <div className={`${className}__canvas${active ? ' active' : ''}`}>
                <h2>Reasons to get out of bed – every single day</h2>
                <p>
                    “We continuously challenge the status quo and are committed
                    to improving dental health. Every single day.”- this is the
                    purpose of our organization. In other words: this is the
                    reason why we get out of bed – every single day. In
                    everything we do, we believe that the status quo must
                    perpetually be questioned in order to move on.
                </p>
                <p>
                    We prefer an integrated and interdisciplinary approach by
                    joining forces, knowledge, and technologies with experts and
                    other competent players in the industry, and even
                    cross-industry. We are committed to creating and
                    implementing sustainable concepts in order to move
                    orthodontics forward. We provide high-quality education and
                    state-of-the-art products, equipment, and digitization
                    services.
                </p>
                <p>
                    It is both our customers and our employees that are key to
                    success. Management’s duty is to inspire trust while
                    motivating our staff and supporting them in embracing
                    change. “The way we do things around here” – also called
                    “company culture” – is, in our case, characterized by
                    respect, active listening, sharing, collaborating, finding
                    solutions, and encouragement.
                </p>
                <p>
                    To help our employees achieve their best, we strive for a
                    culture that builds on trust and collaboration, fosters
                    diversity, promotes learning and engagement, and encourages
                    people to take both responsibility and ownership. It goes
                    without saying that we conduct our business in a legal and
                    ethical manner in complete compliance with international,
                    national, and regional laws and regulations.
                </p>
            </div>
        </div>
    );
}

export default styled(Why)`
    display: flex;
    flex-direction: column;
    padding: 20px 0vw 20px 0vw;
    width: calc(80vw + 20px);
    font-family: Montserrat, Arial, Sans-Serif;
    font-size: 25px;

    p {
        font-weight: 300;
        font-size: 18px;
        line-height: 1.4em;
    }
    align-self: center;
    &__why {
        cursor: pointer;
        background-color: ${params => params.theme.background.primary};
        padding: 40px;
        text-align: center;
        color: white;
        &.active {
            background-color: #9b8b74;
            img {
                transform: rotate(90deg);
            }
        }
        &:hover {
            background-color: #9b8b74;
        }
        img {
            width: 25px;
            padding-right: 10px;
            transform-origin: 50% 80%;
            transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
        }
    }

    &__canvas {
        display: block;
        height: 0px;
        transform: scaleY(0);
        transition: transform 0.25s ease-in-out, height 0.25s ease-in-out;
        transform-origin: top;
        background-color: ${params => params.theme.background.secondary};
        color: transparent;

        font-size: 20px;
        h2 {
            font-weight: normal;
        }

        a:link,
        a:visited {
            text-decoration: none;
            color: transparent;
        }

        &.active {
            color: white;
            transform: scaleY(1);
            height: 100%;
            margin-top: 20px;
            padding: 40px;
            a:link,
            a:visited {
                color: white;
                &:hover {
                    color: ${params => params.theme.background.primary};
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        width: calc(90vw + 10px);
        padding: 10px 0px 10px 0px;
        &__why {
            font-size: 15px;
            padding: 30px 10px;
        }
        &__canvas {
            font-size: 15px;
            &.active {
                margin-top: 10px;
            }
        }
    }

    @media screen and (max-width: 700px) {
        width: calc(90vw + 10px);
        &__why {
            font-size: 15px;
            padding: 20px 10px;
        }
    }
`;
