import React from 'react';
import styled from 'styled-components';

import OW from './OW.svg';
import MG from './M.svg';
import ICCO from './ICCO.svg';
import cross from './cross.svg';
import arrowRight from './arrow-right.svg';

const size = 30;
const size900 = 20;
const size800 = 15;
const size700 = 10;
const size400 = 5;

function CompaniesContainer({ className }: { className?: string }) {
    return (
        <div className={className} style={{ position: 'relative' }}>
            <div
                className="mirkona"
                onClick={() => {
                    window.open('https://www.mikrona.com/', '_blank');
                }}
            >
                <img src={MG} alt="" />
                <div className="linkmessage">
                    <img src={arrowRight} alt="arrow" />
                    WEBSITE
                </div>
            </div>

            <div className="cross-container">
                <div className="cross">
                    <img src={cross} alt="cross" />
                </div>
            </div>

            <div
                className="ortho"
                onClick={() => {
                    window.open('http://orthowalker.ch/', '_blank');
                }}
            >
                <img src={OW} alt="" />
                <div className="linkmessage">
                    <img src={arrowRight} alt="arrow" />
                    WEBSITE
                </div>
            </div>

            <div
                className="icco ortho"
                onClick={() => {
                    window.open('https://www.iccortho.org/', '_blank');
                }}
            >
                <img src={ICCO} alt="ICCO" />
                <div className="linkmessage">
                    <img src={arrowRight} alt="arrow" />
                    WEBSITE
                </div>
            </div>
        </div>
    );
}

export default styled(CompaniesContainer)`
    display: flex;
    justify-content: space-between;

    .cross-container {
        position: absolute;
        left: 50%;
        top: -${size700 / 2}rem;
        transform: translateX(-50%);
        z-index: 200;

        img {
            width: ${size700}rem;
            height: ${size700}rem;
        }
    }

    .mirkona,
    .ortho {
        background-color: ${props => props.theme.background.primary};
        height: 45vw;
        width: 32vw;
        cursor: pointer;
        position: relative;
    }

    .mirkona > img,
    .ortho > img {
        width: 40%;
        height: 100%;
        padding-left: 30%;
    }

    .ortho.icco > img {
        width: 22%;
        height: 100%;
        padding-left: 40%;
    }

    .linkmessage {
        opacity: 0;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: 50%;
        left: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        font-weight: 400;
        font-size: 20px;
        font-weight: bold;
        transform: translate(-50%, -50%);
        & > img {
            height: 15px;
            padding-right: 15px;
        }
    }

    .mirkona:hover {
        background-color: #9b8b74;
        .linkmessage {
            opacity: 1;
        }
        & > img {
            opacity: 0;
        }
    }

    .ortho:hover {
        background-color: #00b5e0;
        .linkmessage {
            opacity: 1;
        }
        & > img {
            opacity: 0;
        }
    }

    .icco:hover {
        background-color: rgb(189, 74, 196);
        .linkmessage {
            opacity: 1;
        }
        & > img {
            opacity: 0;
        }
    }

    @media screen and (max-width: 1200px) {
        .ortho,
        .mirkona {
            height: 45vw;
            width: 32vw;
        }
        .linkmessage {
            font-size: 17px;
        }
    }

    @media screen and (max-width: 900px) {
        .cross-container {
            top: -${size700 / 2}rem;
            img {
                width: ${size700}rem;
                height: ${size700}rem;
            }
        }
        .ortho,
        .mirkona {
            height: 45vw;
            width: 32vw;
        }

        .linkmessage {
            font-size: 15px;
            img {
                height: 10px;
                padding-right: 5px;
            }
        }
    }

    @media screen and (max-width: 700px) {
        .cross-container {
            top: -${size400 / 2}rem;

            img {
                width: ${size400}rem;
                height: ${size400}rem;
            }
        }
        .ortho,
        .mirkona {
            height: 45vw;
            width: 32vw;
        }
        .linkmessage {
            font-size: 12px;
        }
    }

    @media screen and (min-width: 1024px) {
        .cross-container {
            top: -${size800 / 2}rem;

            img {
                width: ${size800}rem;
                height: ${size800}rem;
            }
        }
    }

    @media screen and (min-width: 1440px) {
        .cross-container {
            top: -${size / 2}rem;

            img {
                width: ${size}rem;
                height: ${size}rem;
            }
        }
    }
`;
