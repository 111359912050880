import * as React from 'react';
import { useSlider } from './hooks/useSlider';
import styled from 'styled-components';

import cross from './kreuz.svg';
import crossBlack from './kreuz-black.svg';

interface height {
    [key: string]: string;
}
interface SliderProps {
    height?: height;
    vertical?: boolean;
}

const Slider = styled('div')<SliderProps>`
    position: relative;
    overflow: hidden;
    ${params => {
        if (!params.height) {
            return '';
        }
        const { height } = params;
        return Object.keys(height).reduce((a, b) => {
            if (b === 'default') {
                return `${a} height: ${height[b]};`;
            }
            return `${a}
            @media screen and (max-width: ${b}) {
                height: ${height[b]}
            }
            `;
        }, '');
    }};
`;

const SliderIndicators = styled.ol`
    position: absolute;
    bottom: 4em;
    left: 8em;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    list-style: none;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
        bottom: 3em;
        left: 5em;
    }
    @media screen and (max-width: 1000px) {
        bottom: 2em;
        left: 2em;
    }
`;

const SliderIndicator = styled(
    ({
        active,
        className,
        onClick,
        key,
    }: {
        active?: boolean;
        className?: string;
        onClick?: () => any;
        key?: number;
    }) => (
        <li className={className} key={key} onClick={onClick}>
            <img src={active ? crossBlack : cross} alt="slider-indicator" />
        </li>
    )
)`
    color: ${props => (props.active ? 'black' : '#9C8B72')}
    position: relative;
    font-weight: ${props => (props.active ? '900' : '100')};
    font-size: 2em;
    display: inline-flex;
    width: 1.25rem;
    margin: 0 0.3em;
    cursor: pointer;

    &:hover{
        color: black;
    }

    img {
        height: 20px;
    }

    @media screen and (max-width: 1200px) {
        img {
            height: 15px;
        }
    }
    @media screen and (max-width: 1000px) {
        img {
            height: 10px;
        }
    }

    @media screen and (max-width: 400px) {
        margin: 0 0.05em;
    }

    @media screen and (min-width: 1024px) {
        width: 1em;
    }
`;

const SliderContent = styled('div')<SliderProps>`
    display: flex;
    flex-direction: ${params => (params.vertical ? 'column' : 'row')};
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
`;

const SliderItem = styled('div')<SliderProps>`
    width: 100%;
    object-fit: contain;
    height: ${params => {
            return params.height
                ? params.height
                : params.vertical
                ? '100vh'
                : '100%';
        }}
        ${params => {
            if (!params.height) {
                return '';
            }
            if (!params.vertical) {
                return '100%';
            }

            const { height } = params;
            return Object.keys(height).reduce((a, b) => {
                if (b === 'default') {
                    return `${a} height: ${height[b]};`;
                }
                return `${a}
            @media screen and (max-width: ${b}) {
                height: ${height[b]}
            }
            `;
            }, '');
        }};

    & > * {
        height: ${params => {
            return params.vertical && params.vertical === true
                ? '100%'
                : '90vh';
        }};
    }
`;

export interface SliderContainerProps {
    interval?: number;
    children?: React.ReactNode;
    height?: height;
    vertical?: boolean;
}

export const SliderContainer = ({
    children,
    interval = 5000,
    height,
    vertical = false,
}: SliderContainerProps) => {
    const slides = React.Children.toArray(children);
    const length = slides.length;
    const [active, setActive, handlers, style] = useSlider(
        length,
        interval,
        vertical
    );

    if (length === 0) {
        return null;
    }

    return (
        <Slider height={height}>
            <SliderIndicators>
                {slides.map((_, index) => (
                    <SliderIndicator
                        active={active === index}
                        onClick={() => setActive(index)}
                        key={index}
                    />
                ))}
            </SliderIndicators>
            <SliderContent
                vertical={vertical}
                height={height}
                {...handlers}
                style={style}
            >
                <SliderChild vertical={vertical} height={height}>
                    {slides[slides.length - 1]}
                </SliderChild>
                {slides.map((slide, index) => (
                    <SliderChild
                        vertical={vertical}
                        height={height}
                        key={index}
                    >
                        {slide}
                    </SliderChild>
                ))}
                <SliderChild vertical={vertical} height={height}>
                    {slides[0]}
                </SliderChild>
            </SliderContent>
        </Slider>
    );
};

export const SliderChild: React.FC<SliderProps> = ({
    children,
    vertical = false,
    height,
}) => {
    return (
        <SliderItem vertical={vertical} height={height}>
            {children}
        </SliderItem>
    );
};
