import { ContactActionTypes, CONTACT_CLOSE, CONTACT_OPEN } from './types';
import { AppThunk } from 'store';

export const openContact = (): AppThunk => async dispatch => {
    dispatch(openContactAction());
};

export function openContactAction(): ContactActionTypes {
    return {
        type: CONTACT_OPEN,
    };
}

export const closeContact = (): AppThunk => async dispatch => {
    dispatch(closeContactAction());
};

export function closeContactAction(): ContactActionTypes {
    return {
        type: CONTACT_CLOSE,
    };
}
