export interface ContactState {
    open: boolean;
}

interface OpenContactAction {
    type: typeof CONTACT_OPEN;
}

interface CloseContactAction {
    type: typeof CONTACT_CLOSE;
}

export type ContactActionTypes = OpenContactAction | CloseContactAction;

export const CONTACT_OPEN = 'Contact_CONTACT_OPEN';
export const CONTACT_CLOSE = 'Contact_CONTACT_CLOSE';
