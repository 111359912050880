import React from 'react';
import { SliderContainer, SliderChild } from 'components/Slider';
import styled from 'styled-components';

const Slide = styled.div`
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;

    @media screen and (max-width: 900px) {
        height: 50vh;
    }

    @media screen and (max-width: 700px) {
        height: 40vh;
    }
`;

const Slide2 = styled.div`
    background-color: #eff1f2;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    font-family: ${params => params.theme.fontFamily};
    font-size: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;

    & > div {
        text-align: center;
        padding-left: 20vw;
        padding-right: 20vw;
        padding-bottom: 10vh;
    }
    @media screen and (max-width: 1200px) {
        & > div {
            font-size: 1.5rem;

            padding-bottom: 8rem;
        }
    }

    @media screen and (max-width: 900px) {
        height: 50vh;

        & > div {
            font-size: 1.5rem;
        }
    }

    @media screen and (max-width: 700px) {
        height: 40vh;
        & > div {
            font-size: 1rem;
            padding-bottom: 4rem;
        }
    }
`;

function HomeSlider({ className }: { className?: string }) {
    return (
        <div className={className}>
            <SliderContainer
                interval={5000}
                height={{
                    default: '80vh',
                    '900px': '50vh',
                    '700px': '40vh',
                }}
                vertical={true}
            >
                <SliderChild>
                    <Slide
                        style={{
                            backgroundImage: `url(/img/slider/Image1.jpg)`,
                        }}
                    />
                </SliderChild>
                <SliderChild>
                    <Slide
                        style={{
                            backgroundImage: `url(/img/slider/mikrona-group-image-2.jpg)`,
                        }}
                    />
                </SliderChild>
                <SliderChild>
                    <Slide
                        style={{
                            backgroundImage: `url(/img/slider/mikrona-group-image-3.jpg)`,
                        }}
                    />
                </SliderChild>
                <SliderChild>
                    <Slide2>
                        <div>
                            We continuously challenge the status quo and are
                            committed to improving dental health. Every single
                            day.
                        </div>
                    </Slide2>
                </SliderChild>
            </SliderContainer>
        </div>
    );
}

export default styled(HomeSlider)`
    padding-top: 120px;
    @media screen and (max-width: 500px) {
        padding-top: 100px;
    }
`;
