import { useReducer, useEffect } from 'react';
import { useSwipeable, SwipeableHandlers, EventData } from 'react-swipeable';

function previous(length: number, current: number) {
    return (current - 1 + length) % length;
}

function next(length: number, current: number) {
    return (current + 1) % length;
}

function threshold(target: EventTarget, vertical: boolean) {
    const width = vertical
        ? (target as HTMLElement).clientHeight
        : (target as HTMLElement).clientWidth;
    return width / 3;
}

const transitionTime = 400;
const elastic = `transform ${transitionTime}ms cubic-bezier(0.68, -0.55, 0.265, 1.55)`;
const smooth = `transform ${transitionTime}ms ease`;

interface SliderState {
    offset: number;
    desired: number;
    active: number;
}

const initialSliderState: SliderState = {
    offset: 0,
    desired: 0,
    active: 0,
};

interface SliderNextAction {
    type: 'next';
    length: number;
}

interface SliderPrevAction {
    type: 'prev';
    length: number;
}

interface SliderJumpAction {
    type: 'jump';
    desired: number;
}

interface SliderDoneAction {
    type: 'done';
}

interface SliderDragAction {
    type: 'drag';
    offset: number;
}

type SliderAction =
    | SliderJumpAction
    | SliderNextAction
    | SliderPrevAction
    | SliderDragAction
    | SliderDoneAction;

function sliderReducer(state: SliderState, action: SliderAction): SliderState {
    switch (action.type) {
        case 'jump':
            return {
                ...state,
                desired: action.desired,
            };
        case 'next':
            return {
                ...state,
                desired: next(action.length, state.active),
            };
        case 'prev':
            return {
                ...state,
                desired: previous(action.length, state.active),
            };
        case 'done':
            return {
                ...state,
                offset: NaN,
                active: state.desired,
            };
        case 'drag':
            return {
                ...state,
                offset: action.offset,
            };
        default:
            return state;
    }
}

function swiped(
    e: EventData,
    dispatch: React.Dispatch<SliderAction>,
    length: number,
    dir: 1 | -1,
    vertical: boolean
) {
    const t = threshold(e.event.target as EventTarget, vertical);
    const d = vertical ? dir * e.deltaY : dir * e.deltaX;

    if (d >= t) {
        dispatch({
            type: dir > 0 ? 'next' : 'prev',
            length,
        });
    } else {
        dispatch({
            type: 'drag',
            offset: 0,
        });
    }
}

export function useSlider(
    length: number,
    interval: number,
    vertical: boolean = false
): [number, (n: number) => void, SwipeableHandlers, React.CSSProperties] {
    const [state, dispatch] = useReducer(sliderReducer, initialSliderState);
    const handlers = useSwipeable({
        onSwiping(e) {
            dispatch({
                type: 'drag',
                offset: vertical ? -e.deltaY : -e.deltaX,
            });
        },
        onSwipedLeft(e) {
            swiped(e, dispatch, length, 1, vertical);
        },
        onSwipedUp(e) {
            swiped(e, dispatch, length, 1, vertical);
        },
        onSwipedRight(e) {
            swiped(e, dispatch, length, -1, vertical);
        },
        onSwipedDown(e) {
            swiped(e, dispatch, length, -1, vertical);
        },
        trackMouse: true,
        trackTouch: true,
    });

    useEffect(() => {
        const id = setTimeout(
            () => dispatch({ type: 'next', length }),
            interval
        );
        return () => clearTimeout(id);
    }, [state.offset, state.active, interval, length]);

    useEffect(() => {
        const id = setTimeout(() => dispatch({ type: 'done' }), transitionTime);
        return () => clearTimeout(id);
    }, [state.desired]);

    const style: React.CSSProperties = vertical
        ? {
              transform: 'translateY(0)',
              height: `${100 * (length + 2)}%`,
              top: `-${(state.active + 1) * 100}%`,
          }
        : {
              transform: 'translateX(0)',
              width: `${100 * (length + 2)}%`,
              left: `-${(state.active + 1) * 100}%`,
          };

    if (state.desired !== state.active) {
        const dist = Math.abs(state.active - state.desired);
        const pref = Math.sign(state.offset || 0);
        const dir =
            (dist > length / 2 ? 1 : -1) *
            Math.sign(state.desired - state.active);
        const shift = (100 * (pref || dir)) / (length + 2);
        style.transition = smooth;
        style.transform = vertical
            ? `translateY(${shift}%)`
            : `translateX(${shift}%)`;
    } else if (!isNaN(state.offset)) {
        if (state.offset !== 0) {
            style.transform = vertical
                ? `translateY(${state.offset}px)`
                : `translateX(${state.offset}px)`;
        } else {
            style.transition = elastic;
        }
    }

    return [
        state.active,
        n => dispatch({ type: 'jump', desired: n }),
        handlers,
        style,
    ];
}
