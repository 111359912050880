export interface SystemState {
    loggedIn: boolean;
    userName: string;
    firstName: string;
    lastName: string;
}

interface LoginRequestAction {
    type: typeof LOGIN_REQUEST;
}

interface LoginSuccessAction {
    type: typeof LOGIN_SUCCESS;
    payload: SystemState;
}

interface LoginFailureAction {
    type: typeof LOGIN_FAILURE;
    error: string;
}

export type SystemActionTypes =
    | LoginRequestAction
    | LoginSuccessAction
    | LoginFailureAction;

export const LOGIN_REQUEST = 'System_LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'System_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'System_LOGIN_FAILURE';
