import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';

import arrow from './arrow-right.svg';

function Who({ className }: { className?: string }) {
    const [active, setActive] = useState(false);
    const ref = useRef(null as any);

    const toggle = useCallback(() => {
        setActive(!active);
    }, [active]);

    return (
        <div className={className}>
            <div
                className={`${className}__who${active ? ' active' : ''}`}
                onClick={toggle}
                ref={ref}
            >
                <img src={arrow} alt="" />
                WHO
            </div>
            <div className={`${className}__canvas${active ? ' active' : ''}`}>
                <h2>Who we are and what we do</h2>
                <p>
                    Headquartered in Schlieren (Switzerland), MIKRONA GROUP AG (
                    <a
                        href="https://mikrona-group.ch/"
                        rel="noopener noreferrer"
                    >
                        mikrona-group.com
                    </a>
                    ) is a dedicated partner for orthodontists, dentists, and
                    other specialized professionals committed to providing the
                    most beneficial orthodontic solutions for children,
                    adolescents, and adults. The Group unites 2 corporate brands
                    MIKRONA and ORTHO WALKER, with the ICCO Association. The two
                    corporate brands offer a long and strong tradition of
                    accomplished quality whilst the association offers
                    professionals a global network and opportunities for
                    first-class further education and development.
                </p>
                <p>
                    MIKRONA (
                    <a href="https://mikrona.com" rel="noopener noreferrer">
                        www.mikrona.com
                    </a>
                    ) is based on 60 years of experience in the dental field and
                    operates on a global scale in over 30 countries. In close
                    cooperation with clinicians, technicians, and experts from
                    related disciplines, MIKRONA develops and supplies concepts
                    based on genuine Swiss values such as reliability,
                    responsiveness, and innovation. We strongly believe in
                    sustainability based on unrivalled craftmanship, timeless
                    design, and reliable functionality.
                </p>
                <p>
                    ORTHO WALKER (
                    <a
                        href="https://orthowalker-kieferorthopaedie.ch/"
                        rel="noopener noreferrer"
                    >
                        www.orthowalker.ch
                    </a>
                    ) is a well-established distributor of orthodontic
                    consumables in Switzerland with a reputation of first-class
                    competence, high reliability, a wide on-stock product
                    portfolio, and fast delivery services. ORTHO WALKER’s
                    products are accompanied by a comprehensive choice of
                    training courses, education events, and study club meetings.
                </p>
                <p>
                    ICCO - The Interdisciplinary Competence Center of
                    Orthodontics (
                    <a
                        href="https://www.iccortho.org/"
                        rel="noopener noreferrer"
                    >
                        www.iccortho.org
                    </a>
                    ) is an independent, non-profit association of professionals
                    in orthodontics. Its objective is the promotion and
                    dissemination of knowledge in orthodontics and related
                    fields. Members of the ICCO benefit from quality
                    events,programmes and educational support that promise to
                    enhance the profession.
                </p>
            </div>
        </div>
    );
}

export default styled(Who)`
    display: flex;
    flex-direction: column;
    padding: 20px 0vw 0vw 0vw;
    width: calc(80vw + 20px);
    font-family: Montserrat, Arial, Sans-Serif;
    font-size: 25px;
    p {
        font-weight: 300;
        font-size: 18px;
        line-height: 1.4em;
    }
    align-self: center;
    &__who {
        cursor: pointer;
        background-color: ${params => params.theme.background.primary};
        padding: 40px;
        text-align: center;
        color: white;
        &.active {
            background-color: #9b8b74;
            img {
                transform: rotate(90deg);
            }
        }
        &:hover {
            background-color: #9b8b74;
        }
        img {
            width: 25px;
            padding-right: 10px;
            transform-origin: 50% 80%;
            transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
        }
    }

    &__canvas {
        display: block;
        height: 0px;
        transform: scaleY(0);
        transition: transform 0.25s ease-in-out, height 0.25s ease-in-out;
        transform-origin: top;
        background-color: ${params => params.theme.background.secondary};
        color: transparent;

        font-size: 20px;
        h2 {
            font-weight: normal;
        }

        a:link,
        a:visited {
            text-decoration: none;
            color: transparent;
        }

        &.active {
            color: white;
            transform: scaleY(1);
            height: 100%;
            margin-top: 20px;
            padding: 40px;
            a:link,
            a:visited {
                color: white;
                &:hover {
                    color: ${params => params.theme.background.primary};
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        width: calc(90vw + 10px);
        padding: 10px 0px 0px 0px;
        &__who {
            font-size: 15px;
            padding: 30px 10px;
        }
        &__canvas {
            font-size: 15px;
            &.active {
                margin-top: 10px;
            }
        }
    }

    @media screen and (max-width: 700px) {
        width: calc(90vw + 10px);
        &__who {
            font-size: 15px;
            padding: 20px 10px;
        }
    }
`;
