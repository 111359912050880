import React from 'react';
import styled from 'styled-components';

import logo from './logo.svg';
import Contact from 'components/Contact';

function Navbar({ className }: { className?: string }) {
    return (
        <div className={className}>
            <div className="contact">
                <Contact />
            </div>
            <div className="logo">
                <img src={logo} alt="logo" />
            </div>
            <div className="fix"></div>
        </div>
    );
}

export default styled(Navbar)`
    z-index: 100;
    margin: 0;
    height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: ${props => props.theme.background.navbar};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .contact {
        margin-right: auto;
        margin-left: 40px;
    }
    .logo {
        display: flex;
        height: 40px;
        justify-content: center;
    }
    .fix {
        margin-left: auto;
        margin-right: 100px;
    }
    @media screen and (max-width: 900px) {
        .fix {
            margin-right: 40px;
        }
    }
    @media screen and (max-width: 500px) {
        height: 100px;
        .contact {
            margin-left: 10px;
        }
        .fix {
            margin-right: 0px;
        }
    }
    @media screen and (max-width: 400px) {
        img {
            width: 80%;
        }
    }
`;
