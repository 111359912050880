import { ThemedStyledProps } from 'styled-components';

export default {
    background: {
        primary: '#2F383C',
        secondary: '#9C8B72',
        navbar: 'white',
    },
    color: {
        primary: 'white',
        secondary: 'white',
    },
    fontFamily: 'Montserrat, Arial, Sans-Serif',
    active: '#9C8B72',
    spacing: {
        none: '0px',
        gutter: '24px',
        xxsmall: '4px',
        xsmall: '8px',
        small: '12px',
        medium: '20px',
        large: '32px',
        xlarge: '48px',
        xxlarge: '96px',
    },
    breakPoints: {
        phoneOnly: '600px',
        tabletPortrait: '600px',
        tabletLandscape: '900px',
        desktop: '1200px',
        desktopBig: '1800px',
    },
};

export type ISpacing =
    | 'none'
    | 'gutter'
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge';

export function getPadding<T>(
    index = 0
): (props: ThemedStyledProps<any, any>) => string {
    return ({
        padding,
        theme,
        paddingBottom,
        paddingTop,
        paddingLeft,
        paddingRight,
        paddingX,
        paddingY,
    }) => {
        function getArrayValue(prop: ISpacing[], index: number): ISpacing {
            for (let i = index; i >= 0; i--) {
                if (prop[i]) {
                    return prop[i];
                }
            }
            return theme.spacing.none;
        }

        let left = theme.spacing.none,
            right = theme.spacing.none,
            top = theme.spacing.none,
            bottom = theme.spacing.none;

        if (padding) {
            let spacing;
            if (Array.isArray(padding)) {
                spacing = theme.spacing[getArrayValue(padding, index)];
            } else {
                spacing = theme.spacing[padding];
            }
            left = spacing;
            right = spacing;
            top = spacing;
            bottom = spacing;
        }

        if (paddingX) {
            let spacing;
            if (Array.isArray(paddingX)) {
                spacing = theme.spacing[getArrayValue(paddingX, index)];
            } else {
                spacing = theme.spacing[paddingX];
            }
            right = spacing;
            left = spacing;
        }

        if (paddingY) {
            let spacing;
            if (Array.isArray(paddingY)) {
                spacing = theme.spacing[getArrayValue(paddingY, index)];
            } else {
                spacing = theme.spacing[paddingY];
            }
            top = spacing;
            bottom = spacing;
        }

        if (paddingTop) {
            let spacing;
            if (Array.isArray(paddingTop)) {
                spacing = theme.spacing[getArrayValue(paddingTop, index)];
            } else {
                spacing = theme.spacing[paddingTop];
            }
            top = spacing;
        }

        if (paddingRight) {
            let spacing;
            if (Array.isArray(paddingRight)) {
                spacing = theme.spacing[getArrayValue(paddingRight, index)];
            } else {
                spacing = theme.spacing[paddingRight];
            }
            right = spacing;
        }

        if (paddingLeft) {
            let spacing;
            if (Array.isArray(paddingLeft)) {
                spacing = theme.spacing[getArrayValue(paddingLeft, index)];
            } else {
                spacing = theme.spacing[paddingLeft];
            }
            left = spacing;
        }

        return `${top} ${right} ${bottom} ${left}`;
    };
}
