import {
    ContactState,
    ContactActionTypes,
    CONTACT_OPEN,
    CONTACT_CLOSE,
} from './types';
import { RootState } from '../../store';

const initialState: ContactState = {
    open: false,
};

export function contactReducer(
    state = initialState,
    action: ContactActionTypes
): ContactState {
    switch (action.type) {
        case CONTACT_OPEN: {
            return {
                ...state,
                open: true,
            };
        }
        case CONTACT_CLOSE: {
            return {
                ...state,
                open: false,
            };
        }
        default:
            return state;
    }
}

export const selectContactOpen = (state: RootState) => state.contact.open;
