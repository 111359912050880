import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import icon from './open.svg';
import iconClose from './close.svg';
import { useSelector, useDispatch } from 'react-redux';
import { selectContactOpen } from 'reducers/contact';
import { closeContact, openContact } from 'reducers/contact/actions';

function Contact({ className }: { className?: string }) {
    const dispatch = useDispatch();
    const onOpen = useCallback(() => {
        dispatch(openContact());
    }, [dispatch]);
    const open = useSelector(selectContactOpen);
    // close on click outside of modal
    useEffect(() => {
        if (!open) {
            return;
        }
        function handleModalClose() {
            dispatch(closeContact());
        }

        window.addEventListener('click', handleModalClose);
        return () => {
            window.removeEventListener('click', handleModalClose);
        };
    }, [dispatch, open]);
    return (
        <div className={className}>
            <img
                src={icon}
                alt="contact-icon"
                onClick={onOpen}
                className="open-icon"
            />
        </div>
    );
}

const ContactContainer = ({ className }: { className?: string }) => {
    const active = useSelector(selectContactOpen);
    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        dispatch(closeContact());
    }, [dispatch]);

    return (
        <div
            className={`${className} ${active ? 'active' : ''}`}
            onClick={e => {
                e.stopPropagation();
            }}
        >
            <img
                onClick={onClose}
                className="close-icon"
                alt=""
                src={iconClose}
            />
            <div className="contactInfo">
                <div className="contactInfo__1">MIKRONA GROUP IS</div>
                <div className="contactInfo__2">
                    <div>MIKRONA TECHNOLOGIE AG</div>
                    <div>Wiesenstrasse 36</div>
                    <div>8952 Schlieren</div>
                    <div>Switzerland</div>
                    <div>
                        <a href="tel:+41564184545">P +41 56 418 45 45</a>
                    </div>
                    <div>
                        <a href="mailto:swiss@mikrona.com">
                            swiss@mikrona.com
                        </a>
                    </div>
                    <div>
                        <a
                            style={{ color: '#978B75' }}
                            href="https://www.mikrona.com"
                        >
                            www.mikrona.com
                        </a>
                    </div>
                </div>
                <div className="contactInfo__2">
                    <div>ORTHO-WALKER AG</div>
                    <div>Wiesenstrasse 36</div>
                    <div>8952 Schlieren</div>
                    <div>Switzerland</div>
                    <div>
                        <a href="tel:+41566492400">P +41 56 649 24 00</a>
                    </div>
                    <div>
                        <a href="mailto:info@orthowalker.ch">
                            info@orthowalker.ch
                        </a>
                    </div>
                    <div>
                        <a
                            style={{ color: '#66B5DF' }}
                            href="http://www.orthowalker.ch"
                        >
                            www.orthowalker.ch
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ContactContainerElement = styled(ContactContainer)`
    position: absolute;
    top: 0;
    left: 0;
    height: 0vh;
    width: 0vh;
    opacity: 0;
    background-color: white;
    z-index: 150;
    a:link,
    a:visited {
        text-decoration: none;
        color: white;
    }
    .close-icon {
        opacity: 0;
        display: absolute;
        width: 60px;
        pointer-events: none;
    }
    &.active {
        .close-icon {
            pointer-events: auto;
            opacity: 1;
            padding-left: 40px;
            padding-top: 40px;
        }
        opacity: 1;
        transition: all 0.5s ease-in-out;
        background-color: #2f383c;
        width: 50vw;
        overflow: hidden;
        height: 80vh;
        height: calc(80vh + 120px);
    }

    .contactInfo {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 20%;
        padding-right: 20%;
        padding-top: 30px;
        overflow: hidden;
        font-family: ${params => {
            return params.theme.fontFamily;
        }};
        line-height: 150%;
        font-size: 18px;
        font-weight: bold;
        color: white;

        &__1 {
            font-size: 15px;
            color: #9c8c72;
        }

        &__2 {
            padding-top: 30px;
        }
    }

    @media screen and (max-width: 900px) {
        &.active {
            height: 50vh;
            height: calc(50vh + 120px);
            .contactInfo {
                padding: 15px;
                &__1 {
                    font-size: 20px;
                }
                &__2 {
                    font-size: 15px;
                    line-height: 20px;
                    padding-top: 10px;
                }
            }
            .close-icon {
                padding-left: 5px;
                padding-top: 5px;
                width: 45px;
                height: 45px;
            }
        }
    }

    @media screen and (max-width: 700px) {
        &.active {
            height: 40vh;
            height: calc(40vh + 120px);
            .contactInfo {
                padding: 10px;
                padding-top: 0px;
                &__1 {
                    font-size: 15px;
                }
                &__2 {
                    font-size: 15px;
                    padding-top: 5px;
                }
            }
            .close-icon {
                padding-left: 5px;
                padding-top: 5px;
                width: 30px;
                height: 30px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        &.active {
            height: 40vh;
            height: calc(40vh + 120px);
            .contactInfo {
                padding: 10px;
                padding-top: 0px;
                &__1 {
                    font-size: 10px;
                }
                &__2 {
                    font-size: 10px;
                    line-height: 12px;
                    padding-top: 5px;
                }
            }
            .close-icon {
                padding-left: 5px;
                padding-top: 5px;
                width: 30px;
                height: 30px;
            }
        }
    }
`;

export default styled(Contact)`
    .open-icon,
    .close-icon {
        cursor: pointer;
    }
    img {
        width: 50px;
        height: 50px;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
`;
