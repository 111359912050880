import React from 'react';
import styled from 'styled-components';

import image from './background.svg';

function BackgroundContainer({ children, className = null }: any) {
    return <div className={className}>{children}</div>;
}

export default styled(BackgroundContainer)`
    background-image: url(${image});
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-repeat: repeat-y;
    background-size: 100%;
`;
