import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import logo from './MG-negative.svg';

function Footer({ className }: { className?: string }) {
    const [activeLegal, setActiveLegal] = useState(false);
    const toggleLegal = useCallback(() => {
        setActiveLegal(!activeLegal);
    }, [activeLegal]);
    return (
        <div className={className}>
            <div className="logo">
                <img src={logo} alt="Logo" />
            </div>
            <div className="address">
                <a href="https://goo.gl/maps/mmNNAPPMkasV1ebb9">
                    <div>MIKRONA GROUP</div>
                    <div>Wiesenstrasse 36</div>
                    <div>8952 Schlieren</div>
                    <div>Switzerland</div>
                </a>
            </div>
            <div className="links">
                <div className={`detail ${activeLegal ? 'active' : ''}`}>
                    <div>Content: Mikrona Group</div>
                    <div>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://absolutagentur.ch"
                        >
                            Concept & Design: Absolut Agentur, absolutagentur.ch
                        </a>
                    </div>
                    <div>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://kliqs.ch"
                        >
                            Coding: kliqs GmbH, kliqs.ch
                        </a>
                    </div>
                </div>
                <div onClick={toggleLegal}>Legal Notice</div>
            </div>
        </div>
    );
}

export default styled(Footer)`
    background-color: ${params => params.theme.background.primary};
    height: 300px;
    color: white;
    display: flex;
    align-items: flex-end;
    position: relative;

    padding-left: 10vw;
    padding-right: 10vw;
    font-family: ${params => params.theme.fontFamily};
    font-weight: bold;
    padding-bottom: 50px;
    line-height: 25px;

    .logo {
        flex: 1;
        img {
            width: 100%;
        }
    }
    .address {
        flex: 3;
        padding-left: 30px;
        padding-right: 20px;
        padding-bottom: 5px;

        a:link,
        a:visited {
            text-decoration: none;
            color: white;
        }
    }
    .links {
        cursor: pointer;
        flex: 3;
        display: flex;
        flex-direction: column;
        text-align: right;
        position: relative;
        a:link,
        a:visited {
            text-decoration: none;
            color: white;
        }
        .detail {
            padding: 20px;
            padding-bottom: -20px;
            position: absolute;
            top: 0;
            right: 0;
            transform: translateY(0px) scale(0, 0);
            transform-origin: right;
            color: white !important;
            overflow: hidden;
            opacity: 0;
            background-color: #9b8b74;
            transition: all 0.5s ease-in-out;
            pointer-events: none;
            &.active {
                pointer-events: all;
                transform-origin: right;
                transition: all 0.5s ease-in-out;
                transform: translateY(-150px) scale(1, 1);
                opacity: 1;
                height: auto;
                width: 500px;
                color: white;
            }
        }
    }
    @media screen and (max-width: 900px) {
        height: 200px;
        padding-left: 20px;
        font-size: 16px;
        line-height: 18px;
        padding-right: 20px;
        .logo,
        .links {
            flex: 1;
        }

        .address {
            flex: 1.5;
        }
    }

    @media screen and (max-width: 700px) {
        font-size: 15px;
        line-height: 17px;
        .links .detail.active {
            font-size: 13px;
            width: 400px;
        }
    }

    @media screen and (max-width: 500px) {
        font-size: 10px;
        line-height: 13px;
        .links .detail.active {
            font-size: 10px;
            width: 300px;
        }
    }

    @media screen and (max-width: 380px) {
        font-size: 10px;
        line-height: 13px;
        .links .detail.active {
            font-size: 8px;
            width: 250px;
        }
    }
`;
