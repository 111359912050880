import React from 'react';
import Navbar from 'components/Navbar';
import HomeSlider from 'components/HomeSlider';
import BackgroundContainer from 'components/BackgroundContainer';
import CompaniesContainer from './components/CompaniesContainer';
import Footer from 'components/Footer';
import { ContactContainerElement } from 'components/Contact';
import Who from './components/Who';
import Why from './components/Why';

export default function Home() {
    return (
        <div className="App">
            <ContactContainerElement />
            <Navbar />
            <HomeSlider />
            <BackgroundContainer>
                <CompaniesContainer />
                <Who />
                <Why />
            </BackgroundContainer>
            <Footer />
        </div>
    );
}
